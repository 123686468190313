/*
 * @Author: wuruoyu
 * @Description:
 * @Date: 2020-12-22 10:19:38
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-31 14:12:28
 * @FilePath: \huayun_platform\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/normalize.css'
import '@/plugins/element.js'
import '@/assets/iconfont/iconfont.css'
import * as filterCommon from './assets/js/filters.js'
import Vue2OrgTree from 'vue2-org-tree';
Vue.use(Vue2OrgTree)
import 'vue2-org-tree/dist/style.css';
import less from 'less'
Vue.use(less)
Object.keys(filterCommon).forEach(key => Vue.filter(key, filterCommon[key]))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
